import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-fetch';
import introspectionQueryResultData from '../config/fragmentTypes.json';
import { concatPagination } from '@apollo/client/utilities';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = process.env.BUILD_API_KEY || process.env.GATSBY_CLIENT_API_KEY;

  if (!token) throw new Error('Missing Auth Token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri: `${process.env.API_LOCATION}`,
  fetch,
  credentials: 'include',
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allPosts: concatPagination(),
        },
      },
    },
  }),
});

export default client;
