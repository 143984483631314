/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './apollo';
import GlobalSearch from '../src/components/global/search';

import '../src/css/style.css';

export const wrapRootElement = ({ element }) => (
  <>
    <GlobalSearch />
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </>
);
