module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"mediaTypes":["text/mdx"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en"],"defaultLanguage":"en","siteUrl":"https://weremote.eu","i18nextOptions":{"ns":["common","nav","options","listings"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-32468134-12","head":false,"anonymize":true,"respectDNT":true,"cookieDomain":"weremote.eu"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://a7e42dd06aee4343865e5353e6cd6815@sentry.io/1437584","environment":"production"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"weremote.eu","short_name":"weremote","start_url":"/","background_color":"#ffffff","theme_color":"#3490dc","display":"minimal-ui","icon":"static/weremote-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d407b733aeb94a0fe0789f077bbca84b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/opt/build/repo","configDir":".gatsby"},
    }]
