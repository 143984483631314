// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog/article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-author-tsx": () => import("./../../../src/templates/blog/author.tsx" /* webpackChunkName: "component---src-templates-blog-author-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog/category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-newsletter-signup-confirmation-js": () => import("./../../../src/templates/newsletter-signup-confirmation.js" /* webpackChunkName: "component---src-templates-newsletter-signup-confirmation-js" */),
  "component---src-templates-newsletter-subscription-success-js": () => import("./../../../src/templates/newsletter-subscription-success.js" /* webpackChunkName: "component---src-templates-newsletter-subscription-success-js" */),
  "component---src-templates-policies-index-js": () => import("./../../../src/templates/policies/index.js" /* webpackChunkName: "component---src-templates-policies-index-js" */),
  "component---src-templates-policies-policy-js": () => import("./../../../src/templates/policies/policy.js" /* webpackChunkName: "component---src-templates-policies-policy-js" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-support-tsx": () => import("./../../../src/templates/support.tsx" /* webpackChunkName: "component---src-templates-support-tsx" */)
}

