import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import '@reach/dialog/styles.css';
import { Dialog } from '@reach/dialog';
import { GlobalHotKeys } from 'react-hotkeys';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import debounce from 'lodash.debounce';
import SearchIcon from '../../../../static/assets/feather-icons/search.svg'
import gql from 'graphql-tag';

import './index.css';

const SEARCH_LISTINGS = gql`
  query SearchListings($query: String) {
  searchListings(query: $query, filter: { status: "published" }, sort: { updatedAt: DESC }) {
    id
    title
    hiringStatus
    status
    permalink
    budget
    benefits
    type
    department
    companyBio
    hiringProcess
    createdAt
    updatedAt
    description
    location
    couponCode
    company {
      id
      name
      bio
      hiringProcess
      username
      profilePicture
      headquarters
      website
    }
  }
}
`;

const Suggestions = ({ results, onSuggestionClick }) => {
  if (!results || results.length === 0) return null;

  return (
    <ul className="wr-search-suggestions">
      {results.map((s: string) => (
        <li key={s}>
            <a
            href={`/remote-jobs/${s
              .trim()
              .split(' ')
              .join('+')}`}
              onClick={onSuggestionClick}
              className="wr-search-suggestions__item"
            >
              {s}
            </a>
        </li>
      ))}
      <li className="wr-search-suggestions__footer">
        Not what you&apos;re looking for?{' '}
          <a href={`${process.env.GATSBY_APP_ROOT}/remote-jobs/requests/new`} className="wr-search-suggestions__footer-link">Request a job.</a>
      </li>
    </ul>
  );
};

export const SearchField = ({ onSubmit, focus = false }) => {
  const [suggestions, setSuggestions] = useState([]);
  const getSuggestions = async (q: string) => {
    const { data } = await axios.get(`${process.env.GATSBY_SEARCH_API_HOST}/search/suggest`, {
      params: {
        q,
      },
    });

    setSuggestions(data);
  };
  const debouncedGetSuggesions = debounce(getSuggestions, 600);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          q: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          setSuggestions([]);
          const { q } = values;
          window.location.assign(`/remote-jobs/${q.trim().replace(/\s+/g, '+')}`);
          if (onSubmit instanceof Function) {
            onSubmit(values);
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form className="wr-search">
              <label className="sr-only" htmlFor="q">
                Search Query
              </label>
              <div className="wr-search-wrap">
                <div className="wr-search__icon-wrap">
                  <SearchIcon className="w-6 h-6" />
                </div>
                <Field
                  autoComplete="off"
                  autoFocus={focus}
                  className="wr-search-input"
                  id="q"
                  type="text"
                  name="q"
                  onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                    const keyCode = (e.keyCode || e.code).toString();
                    if (['27', '13'].includes(keyCode)) {
                      setSuggestions([]);
                    }
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('q', e.target.value);
                    debouncedGetSuggesions(e.target.value);
                  }}
                  placeholder="Find your next job"
                />
              </div>
            <Suggestions results={suggestions} onSuggestionClick={onSubmit} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default () => {
  const [visible, setVisible] = useState(false);
  const keyMap = {
    OPEN_SEARCH: ['command+k', 'ctrl+k'],
  };
  const handlers = {
    OPEN_SEARCH: event => {
      event.preventDefault();
      setVisible(!visible);
    },
  };

  return (
    <div className="relative">
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div className="wr-search-menu">
          <button
            className="wr-search-menu__button"
            type="button"
            onClick={() => setVisible(true)}
          >
            <SearchIcon className="wr-search-menu__icon" />
            <span>Quick job search</span>
            <span className="wr-search-menu__shortcut">
              <span className="sr-only">Press</span>
              <span>⌘</span>
              <span className="sr-only">and</span>
              <span>K</span>
            </span>
            <span className="hidden sm:inline">or</span>
            <span className="wr-search-menu__shortcut">
              <span className="sr-only">Press</span>
              <span>⌃</span>
              <span className="sr-only">and</span>
              <span>K</span>
            </span>
          </button>
        <div className="hidden sm:block px-8 py-5">
          <a className="wr-search-menu__link" href={`${process.env.GATSBY_APP_ROOT}/signup`}>Candidates</a>
          <a className="wr-search-menu__link" href={`${process.env.GATSBY_APP_ROOT}/signup/employer`}>Employers</a>
        </div>
      </div>
      <Dialog
        className="bg-transparent"
        isOpen={visible}
        aria-label="Modal window"
        onDismiss={() => setVisible(false)}
      >
        <SearchField onSubmit={() => setVisible(false)} focus />
      </Dialog>
    </div>
  );
};
